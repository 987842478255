<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.ban-announce.download"></custom-page-title>

                <p><a
                    download="" href="/ban/xmljelentes.exe">XML
                    jelentés szerkesztő letöltése</a><br><a
                    download="" href="/ban/OlvassEl.doc">Útmutató az XML jelentés szerkesztőhöz</a></p>

                <p><b>Az XML pénzintézeti jelentések szintaxisa és feldolgozása:</b><br>
                    <a
                        href="/ban/XmlPj_v1.1.pdf" target="_blank">Az XML pénzintézeti jelentések szintaxisa és
                        feldolgozása - 1.1
                        verzió (pdf)</a><br><a
                        href="/ban/XmlPj_v1.1.xsd" target="_blank">séma
                        1.1 verzió (xsd)</a></p>

                <p><b>Régebbi verzió:</b><br>
                    <a
                        href="/ban/XmlPj_v1.0.pdf" target="_blank">Az XML pénzintézeti jelentések szintaxisa és
                        feldolgozása - 1.0
                        verzió (pdf)</a><br><a
                        href="/ban/XmlPj_v1.0.xsd" target="_blank">séma
                        1.0 verzió (xsd)</a></p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpBanAnnounceDownload extends Vue {
}
</script>

<style scoped>

</style>